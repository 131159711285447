<template>
  <span>
    {{amount}}
  </span>
</template>

<script>
import Column from '@/pages/search/controllers/column'

export default {
  inheritAttrs: false,
  computed: {
    amount () {
      if (this.value == null) {
        return null
      }
      const valueType = typeof this.value
      if (valueType === 'object') {
        return this.formatCurrency(this.value.amt, this.value.cur)
      }
      return this.formatCurrency(this.value)
    }
  },
  props: {
    value: {
      type: [Object, Number]
    },
    column: Column
  }
}
</script>
